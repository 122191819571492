/**
 * =========================
 * Theme Scripts
 * =========================
 */

jQuery(function($){

	/**
	 * no-js
	 */

	$('html').removeClass('no-js').addClass('js');

	/**
	 * Mobile Menu JS
	 */

	function toggleMobileMenu() {
		var html = $('html');
		if ( html.attr('data-mobile-menu-is') == 'opened' ) {
			html.attr('data-mobile-menu-is', 'closed');

		} else {
			html.attr('data-mobile-menu-is', 'opened');
		}
	}

	$('[data-mobile-menu-close], [data-mobile-menu-close], [data-mobile-menu-toggle]').click(function(e) {
		e.preventDefault();
		toggleMobileMenu();
	});

	/**
	 * Wordpress navigation
	 */

	$(document).ready(function() {

		$('#site-navigation.SimpleResponsiveNav .menu-item-has-children > a').each(function(){
			$(this).on('click', function(){
				if ( $(window).width() <= 750 ) {
					$(this).next().slideToggle();
				}
			});
		});

		$('#site-navigation.PopoutResponsiveNav #MobileToggle').click(function(){
			if ( $(window).width() <= 750 ) {
				toggleMobileMenu();
			}
		});

		$('#site-navigation.SimpleResponsiveNav #MobileToggle').click(function(){
			if ( $(window).width() <= 750 ) {
				$(this).next().slideToggle();
			}
		});
	});

	/**
	 * Fixed navigation header
	 */

	function stickyNav_setup2() {
		var nav = $('[data-fixed-on-scroll]');
		var navHeight = nav.outerHeight();

		nav.wrap('<div id="masthead-wrapper-2"></div>');
		$('#masthead-wrapper-2').height(navHeight);
	}

	stickyNav_setup2();

	function stickyNav_siteMastheadWrapperHeightFix2() {
		var nav = $('[data-fixed-on-scroll]');
		var navHeight = nav.outerHeight();
		$('#masthead-wrapper-2').height(navHeight);
	}

	function stickyNav_scroll2() {
		var distanceToScroll = $('#masthead-wrapper-2').offset().top;
		var distanceScrolled = $(window).scrollTop();
		if ( distanceScrolled >= distanceToScroll ) {
			$('body').addClass('nav-is-stuck-2');
		} else {
			$('body').removeClass('nav-is-stuck-2');
		}
	}

	if ( $('body').hasClass('home') ) {
		$(window).scroll(function() {
			window.requestAnimationFrame(stickyNav_scroll2);
		});

		$(window).resize(function() {
			stickyNav_siteMastheadWrapperHeightFix2();
			window.requestAnimationFrame(stickyNav_scroll2);
		});
	}

	/**
	 * For styling form fields
	 */

	$(document).ready(function(){
		$('.formField-select, .gform_wrapper select').wrap('<div class="formField-select-holder"></div>');
		$('.InputSearch, .widget_search input[type="search"]').wrap('<div class="formField-search-holder"></div>');
		$('.Submit').wrap('<div class="input-submit-wrapper"></div>');
		$('.Submit').click(function(){
			$(this).parent().addClass('bco-submit-animation');
		});
	});

	/**
	 * Add class to animate the header after scrolling
	 */

	$(document).ready(function(){
		if ( !$('body').hasClass('home') ) {
			$(window).scroll(function() {
				var scroll = $(window).scrollTop();
				if (scroll >= 100) {
					$('body').addClass('header-scrolled');
				} else {
					$('body').removeClass('header-scrolled');
				}
			});
		}
	});

	/**
	 * Wordpress Menu
	 */

	$(document).ready(function() {
		$('#site-navigation .menu-item-has-children > a, #mobile-navigation .menu-item-has-children > a').wrapInner('<span></span>');
		$('#site-navigation .menu-item-has-children > a, #mobile-navigation .menu-item-has-children > a').append('<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>');
	});

	$('#mobile-navigation .menu-item-has-children > a').click(function(){
		$(this).next().slideToggle( 300 );
	});

	/**
	 * Responsive video wrap
	 */

	$(document).ready(function() {
		$('iframe[src*="youtube.com"]').wrap('<div class="responsive-video"></div>');
		$('iframe[src*="youtube-nocookie.com"]').wrap('<div class="responsive-video"></div>');
		$('iframe[src*="player.vimeo.com"]').wrap('<div class="responsive-video"></div>');
	});
	/**
	 * Gravity Forms "active" and "complete" classes
	 */

	$(document).ready(function(){
		var gfields = $('li.gfield .ginput_container input, li.gfield .ginput_container textarea');
		gfields.focus(function(){
			$(this).parent().parent().addClass('field-active');
		});
		gfields.blur(function(){
			$(this).parent().parent().removeClass('field-active');
			if( $(this).val().length !== 0 ) {
				$(this).parent().parent().addClass('field-complete');
			} else {
				$(this).parent().parent().removeClass('field-complete');
			}
		});
	});

	/**
	 * Wolfnet
	 */

	$('.sidebar-widget .wolfnet_widgetTitle').addClass('widget-title');
	$('.wolfnet_widgetBaths option:first-of-type').text('Baths');
	$('.wolfnet_widgetBeds option:first-of-type').text('Beds');
	$('.wolfnet_quickSearchFormButton button').text('Search');

	/**
	 * Smooth Scrolling from #anchor to ID
	 */

	$(document).ready(function(){
		$('a[href*=\\#]:not([href=\\#])').click(function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					$('html,body').animate({
						scrollTop: target.offset().top
					}, 1000);
					return false;
				}
			}
		});
	});

	/**
	 * Magnific popup
	 */

	$(document).ready(function() {

		$('.gallery .gallery-item, [data-image-expand]').magnificPopup({
			// delegate: 'a',
			type: 'image',
			closeOnContentClick: false,
			closeBtnInside: false,
			mainClass: 'mfp-with-zoom mfp-img-mobile',
			image: {
				verticalFit: true
			},
			gallery: {
				enabled: true
			},
			zoom: {
				enabled: true,
				duration: 300, // don't foget to change the duration also in CSS
				opener: function(element) {
					return element.find('img');
				}
			}
		});
	});

	/**
	 * Fixed navigation header
	 */

	function stickyNav_setup() {
		var nav = $('[data-fixed-masthead]');
		// var navHeight = nav.outerHeight();

		nav.wrap('<div id="masthead-wrapper"></div>');
		// $('#masthead-wrapper').height(navHeight);
	}

	stickyNav_setup();

	function stickyNav_siteMastheadWrapperHeightFix() {
		var nav = $('[data-fixed-masthead]');
		var navHeight = nav.outerHeight();
		$('#masthead-wrapper').height(navHeight);
	}

	function stickyNav_scroll() {
		var distanceToScroll = $('#masthead-wrapper').offset().top;
		var distanceScrolled = $(window).scrollTop();
		if ( distanceScrolled >= distanceToScroll ) {
			// $('body').addClass('nav-is-stuck');
			$('html').attr('data-nav-is-stuck', true);
		} else {
			// $('body').removeClass('nav-is-stuck');
			$('html').attr('data-nav-is-stuck', false);
		}
	}
	$(document).ready(function() {
		stickyNav_scroll();

		$(window).scroll(function() {
			window.requestAnimationFrame(stickyNav_scroll);
		});

		$(window).resize(function() {
			window.requestAnimationFrame(stickyNav_scroll);
		});
	});

	/**
	 * doubleTapToGo
	 */

	$(document).ready(function() {
		if ($('html').hasClass('bco-touchevents')) {
			$('#site-navigation .menu-item-has-children > a').doubleTapToGo();
		}

		$('#mobile-navigation .menu-item-has-children > a').doubleTapToGo();
	});

	$('.header-search-container .wolfnet_quickSearchFormButton button, .mobile-search-container .wolfnet_quickSearchFormButton button').text('').append('<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1216 832q0-185-131.5-316.5t-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5 316.5-131.5 131.5-316.5zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225-55.5-273.5 55.5-273.5 150-225 225-150 273.5-55.5 273.5 55.5 225 150 150 225 55.5 273.5q0 220-124 399l343 343q37 37 37 90z"/></svg>');

	$('[data-slider="testimonials"]').slick({
		autoplay: true,
		autoplaySpeed: 6000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		fade: true,
		draggable: true,
		pauseOnHover: false,
		pauseOnFocus: false,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					fade: false,
					draggable: false,
				}
			}
		],
	});

	$('[data-slider="homepage"]').slick({
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		fade: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		speed: 1000,
	});

	$(document).ready(function() {
		if ( !(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera) ) {
		    var s = skrollr.init({
		        forceHeight: false,
		        smoothScrolling: true,
		        smoothScrollingDuration: 200
		    });
		}

		var masonry = $('.rs-testimonials-list.not-slider').masonry();
		var masonryActive = true;
		if (window.matchMedia('(min-width: 768px)').matches) {
			
		} else {
			if (masonryActive) {
				masonry.masonry('destroy');
			}
		}
	})

	$('.rs-testimonials-list.is-slider').slick({
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		fade: true,
		draggable: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		speed: 1000,
		mobileFirst: true,
		// responsive: [
		// 	{
		// 		breakpoint: 1000,
		// 		settings: {
		// 			slidesToShow: 2,
		// 			fade: false
		// 		}
		// 	}
		// ]
	});

});
